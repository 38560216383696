import React, { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'component/Loadable';

const DashboardDefault = Loadable(lazy(() => import('../views/Dashboard')));

const CallCenter = Loadable(lazy(() => import('../views/CallCenter')));

const ScheduleProfessionalPage = Loadable(lazy(() => import('../views/ScheduleProfessionalPage')));

const UtilsTypography = Loadable(lazy(() => import('../views/Utils/Typography')));

const PersonalizationPage = Loadable(lazy(() => import('../views/PersonalizationPage')));

const RegistrationsCouponsPage = Loadable(lazy(() => import('../views/Registrations/CouponsPage')));
const RegistrationsProductsPage = Loadable(lazy(() => import('../views/Registrations/ProductsPage')));
const RegistrationsProfessionalsPage = Loadable(lazy(() => import('../views/Registrations/ProfessionalsPage')));
const RegistrationsServicesPage = Loadable(lazy(() => import('../views/Registrations/ServicesPage')));
const RegistrationsUsersPage = Loadable(lazy(() => import('../views/Registrations/UsersPage')));
const RegistrationsCustomersPage = Loadable(lazy(() => import('../views/Registrations/CustomersPage')));

const SettingsPaymentsPage = Loadable(lazy(() => import('../views/Settings/PaymentsPage')));
const SettingsSchedulesPage = Loadable(lazy(() => import('../views/Settings/SchedulesPage')));

const ReportsAttendancePage = Loadable(lazy(() => import('../views/Reports/AttendancePage')));
const ReportsClientsPage = Loadable(lazy(() => import('../views/Reports/ClientsPage')));
const ReportsCouponsPage = Loadable(lazy(() => import('../views/Reports/CouponsPage')));
const ReportsProductsPage = Loadable(lazy(() => import('../views/Reports/ProductsPage')));
const ReportsProfessionalsPage = Loadable(lazy(() => import('../views/Reports/ProfessionalsPage')));
const ReportsRatingsPage = Loadable(lazy(() => import('../views/Reports/RatingsPage')));
const ReportsServicesPage = Loadable(lazy(() => import('../views/Reports/ServicesPage')));
const ReportsClientDetailsPage = Loadable(lazy(() => import('../views/Reports/ClientsPage/ClientDetailsPage')));

const BlockedPage = Loadable(lazy(() => import('../views/BlockedPage')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    { path: '/utils/util-typography', element: <UtilsTypography /> },
    { path: '/call-center', element: <CallCenter /> },
    { path: '/schedule', element: <ScheduleProfessionalPage /> },
    { path: '/personalization', element: <PersonalizationPage /> },
    { path: '/registrations/coupons', element: <RegistrationsCouponsPage /> },
    { path: '/registrations/products', element: <RegistrationsProductsPage /> },
    { path: '/registrations/professionals', element: <RegistrationsProfessionalsPage /> },
    { path: '/registrations/services', element: <RegistrationsServicesPage /> },
    { path: '/registrations/users', element:<RegistrationsUsersPage/>},
    { path: '/registrations/customers', element:<RegistrationsCustomersPage/>},
    { path: '/settings/payments', element: <SettingsPaymentsPage /> },
    { path: '/settings/schedules', element: <SettingsSchedulesPage /> },
    { path: '/reports/attendance', element: <ReportsAttendancePage /> },
    { path: '/reports/clients', element: <ReportsClientsPage /> },
    { path: '/reports/professionals', element: <ReportsProfessionalsPage /> },
    { path: '/reports/coupons', element: <ReportsCouponsPage /> },
    { path: '/reports/products', element: <ReportsProductsPage /> },
    { path: '/reports/ratings', element: <ReportsRatingsPage /> },
    { path: '/reports/services', element: <ReportsServicesPage /> },
    { path: '/reports/clients/:client_id', element: <ReportsClientDetailsPage /> },
    { path: '/blocked', element: <BlockedPage /> },
  ]
};

export default MainRoutes;
